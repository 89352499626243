import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { useState } from 'react';
import Logo from '../images/softmax-logo-54-88.png';

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className='bg-gray-900 top-0 sticky w-full z-50'>
      <div className='flex flex-wrap items-center justify-between  mx-4 p-2'>
        <Link className='flex items-center no-underline text-white' to='/'>
          {/* <svg
            className='fill-current h-8 mr-2 w-8'
            height='54'
            viewBox='0 0 54 54'
            width='54'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z' />
          </svg> */}
          <img className='h-6 mr-2' src={Logo} alt='logo' />
          <span className='font-bold  font-mono text-xl tracking-widest'>
            {site.siteMetadata.title}
          </span>
        </Link>

        <button
          className='block md:hidden border border-white flex items-center px-3 py-2 my-1 rounded text-white'
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className='fill-current h-3 w-3'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <title>Menu</title>
            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          }  uppercase md:block md:flex md:items-center w-full md:w-auto tracking-wider`}
        >
          {[
            {
              route: `/`,
              title: `Главная`,
            },
            {
              route: `/products`,
              title: `Продукты`,
            },
            {
              route: `/services`,
              title: `Сервисы`,
            },
            {
              route: `/about`,
              title: `Команда`,
            },
            {
              route: `/contact`,
              title: `Контакты`,
            },
          ].map((link) => (
            <Link
              className=' rounded  z-20 py-2 px-2 ml-1 lg:ml-4 transform 
              md:hover:translate-x-0 hover:translate-x-1 hover:bg-gray-800 
              block md:inline-block mt-4 md:mt-0 no-underline text-white'
              key={link.title}
              to={link.route}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
