import PropTypes from 'prop-types';
import React from 'react';
import PageTransition from 'gatsby-plugin-page-transitions';

import Footer from './footer';

import Header from './header';

function Layout({ children }) {
  return (
    <PageTransition>
      <div className='flex flex-col font-sans min-h-screen text-gray-900  bg-gray-300'>
        <Header />
        <div className=' flex-grow flex flex-col justify-center '>
          {/* <main className='flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full'> */}
          {children}
          {/* </main> */}
        </div>

        <Footer />
      </div>
    </PageTransition>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
