import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

const Footer = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `);
  return (
    <footer className='bg-gray-900'>
      <nav className='flex flex-wrap justify-center sm:justify-between max-w-4xl mx-auto p-4 md:p-4 text-sm'>
        <p className='text-white mx-2'>
          Created by{` `}
          <a
            className='font-bold no-underline text-blue-300'
            href='https://devadel.com'
          >
            {site.siteMetadata.author}
          </a>
        </p>

        <div>
          <Link
            to='/'
            className=' flex flex-wrap justify-center font-bold no-underline text-white'
          >
            <p>WhatsApp, Telegram </p>
            <p className='mx-2'> +7 911 832-02-10</p>
          </Link>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
